<script setup lang="ts">
const props = defineProps({
  hasBtn: Boolean,
})

const { t } = useLang()
const { locale } = useI18n()

const router = useRouter()

function jump() {
  //   console.log('jump')
  //   router.push(getFullLink('/'))
  location.href = getFullLink('/')
}

function getFullLink(route: string) {
  const { href } = router.resolve({
    path: `/${locale.value}${route}`,
  })
  return href
}
</script>

<template>
  <div class="empty">
    <img src="~/assets/img/coins/empty.png" class="w-[230px] h-[230px] block mx-auto">
    <p class="text-center text-[16px] text-[#000] font-bold">
      {{ t('coins.p_coins_empty') }}
    </p>
    <p v-if="props.hasBtn" class="btn text-[16px] font-bold" @click="jump">
      {{ t('coins.p_coins_btntxt') }}
    </p>
  </div>
</template>

<style scoped lang="scss">
.empty {
  // padding-top: 78px;
  transform: translate(0, -40px);
  .btn {
    width: 340px;
    height: 48px;
    line-height: 48px;
    margin-top: 40px;
    background-color: #00C868;
    border-radius: 8px;
    color: #fff;
    text-align: center;
    cursor: pointer;
  }
}
</style>
